import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import axios from 'axios';

export let tenant = "ship.qa.dhlecommerce.co.uk"
if (tenant.startsWith("#")) {
  //tenant = 'dhlnondevukwcon001b2c.onmicrosoft.com'
  tenant = 'ship.qa.dhlecommerce.co.uk'
}

export let instance = "loginnonprod.dhlecommerce.co.uk"
if (instance.startsWith("#")) {
  instance = 'loginnonprod.dhlecommerce.co.uk'
}

export const reactRedirectUri = window.location.origin;

export let signInPolicy = "B2C_1A_signup_signin-SIT";
if (signInPolicy.startsWith("#")) {
  signInPolicy = reactRedirectUri.includes("dhl-non-uat") ? 'B2C_1A_signup_signin' : 'B2C_1A_signup_signin-SIT';
}
export let applicationID = "ca36e441-b718-4f22-b49d-7a302bf0f50e";
if (applicationID.startsWith("#")) {
  applicationID = 'ca36e441-b718-4f22-b49d-7a302bf0f50e';
}
// const tenantSubdomain = tenant.split('.')[0];
// const instance = `https://${tenantSubdomain}.b2clogin.com/`;
// const signInAuthority = `${instance}${tenant}/${signInPolicy}`;
// const instance = `https://${tenant}`;
const signInAuthority = `https://${instance}/${tenant}/${signInPolicy}`; 
// Msal Configurations
const signInConfig = {
  auth: {
    authority: signInAuthority,
    clientId: applicationID,
    redirectUri: reactRedirectUri,
    validateAuthority: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
};
// Authentication Parameters
let scope = "https://dhlnondevukwcon001b2c.onmicrosoft.com/ca36e441-b718-4f22-b49d-7a302bf0f50e/user_impersonation";
if (scope.startsWith("#")) {
  scope = 'https://dhlnondevukwcon001b2c.onmicrosoft.com/ca36e441-b718-4f22-b49d-7a302bf0f50e/user_impersonation';
}
const authenticationParameters = {
  scopes: [scope],
};
// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin,
};
export const signInAuthProvider = new MsalAuthProvider(signInConfig, authenticationParameters, options);

// setTimeout(() => {
//   const url = `https://${tenantSubdomain}.b2clogin.com/${tenantSubdomain}.onmicrosoft.com/${signInPolicy}/oauth2/v2.0/token?grant_type=refresh_token&client_id=${applicationID}&scope=openid&refresh_token=${sessionStorage.getItem(`msal.${applicationID}.idtoken`)}`
//   axios.post(url, null, { headers: { "content-type": "application/x-www-form-urlencoded" } }).then(res => console.log(res.data)).catch(err => console.log(err))
//   // axios.post(url, {
//   //   grant_type: "refresh_token",
//   //   client_id: applicationID,
//   //   scope: `${applicationID} offline_access`,
//   //   refresh_token: sessionStorage.getItem(`msal.${applicationID}.idtoken`)
//   // }, { headers: { "content-type": "application/x-www-form-urlencoded" } })
//   //   .then(res => console.log(res.data)).catch(err => console.log(err))
// }, 5000);