import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import collectionsDefaultsForm from '../../../../../utils/formConfig/configuration/orderFileDefaultsForm/collectionsDefaultsForm';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { getYupSchemaFromMetaData } from '../../../../../utils/yupSchema/yupSchemaGenerator';
import CustomAccordion from '../../../../../framework/accordion/customAccordion';
import DeliveryOptions from '../../../../../container/shipment/addShipment/deliveryOptions/deliveryOptions';
import CollectionDetails from '../../../../helperComponents/collectionDetails/collectionDetails';
import FormRenderer from '../../../../../framework/Inputs/formRenderer/formRenderer';
import { useDebouncedCallback } from 'use-debounce';
import { axiosConfig } from '../../../../../api/axios';

import {
  formatDeliveryOptions,
  getCustomerAccountID,
  removeUndefinedProperties,
  setDropdownValues,
  getDomesticAccounts,
  setValuesToDeliveryOptions,
  setValuesToDeliveryOptions_domaesticDefaults,
} from '../../../../../utils/helperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import InputButton from '../../../../../framework/Inputs/inputButton/inputButton';
import { GET, PATCH } from '../../../../../api/axios';
import { ServiceEndPoints } from '../../../../../utils/constants/ApiConstant';
import CustomModal from '../../../../../framework/modal/modal';
import ConfirmationDialog from '../../../../addressBook/confirmation/confirmation';
import CollectionDefaultAccounts from '../defaultCollectionAccounts'
import CollectionsServices from './collectionsServices';
import * as actions from '../../../../../store/actions/index';

const { formId, formField } = collectionsDefaultsForm;
let dimsValidation = {};

const CollectionsDefaults = (props) => {
  const { collectionDefaultValues, getOrderDefaults, isFromMarketplace } = props; 
  const [orderFileDetailsValidationSchema, setOrderFileDetailsValidationSchema] = useState(
    getYupSchemaFromMetaData(formField, [], []),
  );
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const userAccounts = useSelector(state => state?.accounts?.userAccounts ?? []);
  const formikRef = useRef(null);
  const dispatch = useDispatch();

  const initialValues = {
  };

  for (let property in formField) {
    if (Object.prototype.hasOwnProperty.call(formField, property)) {
      const field = formField[property];
      if (field.props.type === 'checkbox' || field.props.type === 'radio') {
        initialValues[field.props.name] = field.value;
      } else if (field.props.type === 'array') {
        initialValues[field.props.name] = field.values;
      } else {
        initialValues[field.props.name] = field.value || '';
      }
    }
  }

  useEffect(() => {
    if (collectionDefaultValues) {
      const formikValues = formikRef.current.values;
      collectionDefaultValues['destinationCountry'] = 'GBR';
      const defaultValues = {
        noOfItems: collectionDefaultValues?.parcelDetails?.numberOfItems ?? '',
        weight: collectionDefaultValues?.parcelDetails?.weight ?? '',
        // ...setValuesToDeliveryOptions(collectionDefaultValues),
        ...setValuesToDeliveryOptions_domaesticDefaults(collectionDefaultValues),
        collectionInstructions: collectionDefaultValues?.collectionDetails?.collectionInstructions ?? '',
        descriptionOfGoods: collectionDefaultValues?.collectionDetails?.descriptionOfGoods ?? '',
      };
      removeUndefinedProperties(defaultValues);
      formikRef.current.setValues({ ...formikValues, ...defaultValues });
      const deliveryOptions = collectionDefaultValues.deliveryOptions;

      setTimeout(() => {
        formikRef?.current?.setFieldValue('specialInstructions', deliveryOptions?.specialInstructions ?? '');
        formikRef?.current?.setFieldValue('additionalInstruction', deliveryOptions?.additionalInstruction ?? '');

      }, 10);
    }
  }, [collectionDefaultValues]);
  const reqFields = ['weight', 'specialInstructions', 'additionalInstruction', 'noOfItems', 'collectionInstructions', 'descriptionOfGoods']
  const isFormInValid = (errors, values) => {
    let clonedReqFields = [...reqFields];

    for (let err in errors) {
      if (clonedReqFields.some((fieldName) => fieldName === err)) {
        return true;
      }
    }

    return false;
  };

  const handleSubmit = (formikValues, errors) => {
    if (!isFormInValid(errors, formikValues)) {
      const reqObj = {
        account: formikValues.defaultCollectionAccounts?.accountId,
        parcelDetails: {
          numberOfItems: formikValues.noOfItems || null,
          weight: formikValues.weight || null,
        },
        orderedProduct: formikValues.service?.orderedProduct,
        deliveryOptions: formatDeliveryOptions(formikValues),
        collectionDetails: {
          collectionInstructions: formikValues.collectionInstructions,
          descriptionOfGoods: formikValues.descriptionOfGoods,
        },
      };
      removeUndefinedProperties(reqObj);
      if(isFromMarketplace){
        PATCH(`${ServiceEndPoints.getMarketplaceDefaults}/collectiondefaults?organization=${getCustomerAccountID()}`, reqObj)  
        .then((res) => {
          dispatch(actions.setAppSnackBarData({ msg: "Collections marketplace defaults saved successfully" }))
          getOrderDefaults();
        })
        .catch((err) => {
          console.log(err);
        });
      }else{
        PATCH(`${ServiceEndPoints.getOrderDefaults}/collection?organization=${getCustomerAccountID()}`, reqObj)  
          .then((res) => {
            dispatch(actions.setAppSnackBarData({ msg: "Collections order file defaults saved successfully" }))
            getOrderDefaults(); 
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    else {
      dispatch(actions.setBottomAlert({
        msgs: [
          {
            type: 'error',
            msg: 'The form has errors, please correct and try again',
          },
        ]
      }));
    }

  };

  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  const resetForm = () => {
    formikRef?.current?.resetForm();
    handleDialogClose();
    // formikRef.current.setFieldValue('service', services[0]);
    formikRef.current.setFieldValue('deliveryType', deliveryTypeOptions[0].value)
  };

  const updateValidationSchema = () => {
    setOrderFileDetailsValidationSchema(getYupSchemaFromMetaData(formField, [], []));
  };

  const debounceCountryFeature = useDebouncedCallback(() => {
    axiosConfig
      .get(`${ServiceEndPoints.productCountryFeature}?countrycode=GB`)
      .then((response) => {
        const nonPalletProducts = response.data.filter((val) => val.productCode !== 97 && val.productCode !== 98);

        let allMaxParcelsList = nonPalletProducts.map((x) => x.maximumParcels);
        let allMaxWeightList = nonPalletProducts.map((x) => x.maximumWeight);
        dimsValidation = {
          ...dimsValidation,
          maxParcels: Math.max(...allMaxParcelsList),
          maxWeight: Math.max(...allMaxWeightList),
        };
        formField.weight.validation.validations = formField.weight.validation.validations.filter(
          (x) => x.type !== 'max',
        );
        formField.weight.validation.validations.push({
          type: 'max',
          params: [
            dimsValidation.maxWeight,
            `Declared weight must be less than or equal to ${dimsValidation.maxWeight}.`,
          ],
        });
        formField.weight.props.inputProps.max = dimsValidation.maxWeight;


        formField.noOfItems.validation.validations = formField.noOfItems.validation.validations.filter(
          (x) => x.type !== 'max',
        );
        formField.noOfItems.validation.validations.push({
          type: 'max',
          params: [dimsValidation.maxParcels, `Must be a value between 1 to ${dimsValidation.maxParcels}`],
        });
        formField.noOfItems.props.inputProps.max = dimsValidation.maxParcels;

        updateValidationSchema();
      })
      .catch((err) => {
        console.log(err);
        // console.log('Failed to load');
      });
  }, 500);

  useEffect(() => {
    debounceCountryFeature();
  }, []);



  return (
    <Grid container spacing={2} direction="column">
      <Formik
        initialValues={initialValues}
        validationSchema={orderFileDetailsValidationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
      >
        {({ values, setValues, setFieldValue, setFieldTouched, errors, resetForm }) => {
          return (
            <Form id={formId} noValidate>

              <Grid>
                <CustomAccordion heading="Account setup">
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <CollectionDefaultAccounts
                      formField={formField}
                      formikValues={values}
                      setFieldValue={setFieldValue}
                      tabIndex={props.tabIndex}
                      collectionDefaultValues={props.collectionDefaultValues}
                    />
                  </Grid>
                </CustomAccordion>
                <CustomAccordion heading="Parcel details">
                  <Grid container style={{ width: '100%' }} spacing={3} direction="row">
                    <Grid item xs={12} sm={6}>
                      <Grid container direction="row" spacing={3}>
                        <Grid item xs={6}>
                          <FormRenderer {...formField.noOfItems.props} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                          <FormRenderer {...formField.weight.props} fullWidth />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                  </Grid>
                </CustomAccordion>

                <CustomAccordion heading="Delivery options">
                  <DeliveryOptions
                    formField={formField}
                    formikValues={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    isDomestic={true}
                    isOrderDefaults={true}
                    isSelectDefaultDoorstepinst={true}
                  />
                </CustomAccordion>
                <CustomAccordion heading="Service">
                  <CollectionsServices
                    formField={formField}
                    formikValues={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    collectionDefaultValues={collectionDefaultValues}
                  />
                </CustomAccordion>

                <CustomAccordion heading="Collection details" className="returnOptions-accordion">
                  <CollectionDetails formField={formField} formikValues={values} />
                </CustomAccordion>
                <Grid className="collection-btn-container" container direction="row" justify="flex-end" spacing={3}>
                  <Grid item>
                    <InputButton
                      variant="outlined"
                      className="outlined-btn"
                      onClick={() => setShowConfirmationDialog(true)}
                      label="Reset"
                    />
                  </Grid>
                  <Grid item>
                    <InputButton
                      variant="contained"
                      className="contained-btn"
                      buttonType="submit"
                      onClick={() => handleSubmit(values, errors)}
                      label="Save changes"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      <Grid item></Grid>

      <CustomModal open={showConfirmationDialog} title="Reset Form" onClose={handleDialogClose} maxWidth="sm">
        <ConfirmationDialog
          confirmationMessage="Are you sure you want to reset?"
          onConfirm={resetForm}
          OnCancel={handleDialogClose}
        />
      </CustomModal>
    </Grid>
  );
};

CollectionsDefaults.propTypes = {};


export default CollectionsDefaults;
const deliveryTypeOptions = [
  {
    value: 'deliverToDoorStep',
    label: 'Deliver to doorstep',
  },
  {
    value: 'deliverToServicePoint',
    label: 'Deliver to ServicePoint',
  },
];