import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Divider } from '@material-ui/core';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { abortOnGoingCallsFor, axiosConfig } from '../../../api/axios';
import CustomDialog from '../../../framework/dialog/customDialog';
import { SearchIcon } from '@material-ui/data-grid';
import {
  formatPostalCodeValues,
  removeUndefinedProperties,
  setDropdownValues,
  getCustomerAccountID,
  arrayToString,
  getDomesticAccounts
} from '../../../utils/helperFunctions';
import { doorStepInstructionOptions, MIN_SEARCH_RESULTS_REQUIRED } from '../../../utils/constants/constants';
import { SearchByOptions } from '../../../utils/constants/carriageForwardConstants';
import './deliverToThirdParty.scss';
import CustomAddressTable from '../../../framework/CustomAddressTable/CustomAddressTable';
import AddToAddressAndFav from '../../helperComponents/addToAddressAndFav';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import DeliveryOptions from '../../../container/shipment/addShipment/deliveryOptions/deliveryOptions';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/index';
import AddressSearch from '../../helperComponents/addessSearch/addressSearch';
import AutoSuggestW3W from '../../../container/shipment/addShipment/autoSuggestW3W/AutoSuggestW3W';

const DeliverToThirdParty = (props) => {
  const {
    formField: {
      toAddressType,
      toSearchFor,
      toCustomerNo,
      toPostCode,
      toContactName,
      toBusinessName,
      toAddressLine1,
      toAddressLine2,
      toAddressLine3,
      toTown,
      toCounty,
      toTelephoneNumber,
      toEmailAddress,
      toServiceRequired,
    },
    servicePointAddress,
    setFieldValue,
    formikValues,
    errors,
    setValues,
    setToAddressSearchObj,
    updateValidationSchema
  } = props;

  const allProducts = useSelector(state => state.common.allProducts);

  const [services, setServices] = React.useState([]);
  const [customerData, setCustomerData] = React.useState([]);
  const [postalSearchData, setPostalSearchData] = React.useState([]);
  const [openAddressBookModal, setOpenAddressBookModal] = React.useState(false);
  const [customerNumber, setCustomerNumber] = React.useState('');
  const [shouldSetValues, setValuesToForm] = React.useState(false);

  let addressTypeData = [];
  let leftOverData = [];

  const selectedAccount = useSelector((state) => state.accounts.selectedAccount);
  const userAccounts = useSelector((state) => state.accounts.nonReturnuserAccounts);
  const displayAccounts = getDomesticAccounts(userAccounts)
  const dispatch = useDispatch();

  useEffect(() => {
    setFieldValue('toSearchBy', SearchByOptions[0]);
  }, [setFieldValue]);

  const getCustomerAddressBook = (event) => {
    event.preventDefault();
    const url = `${ServiceEndPoints.getAddressBook}?organization=${getCustomerAccountID()}&${formikValues.toSearchBy.value
      }=${formikValues.toSearchFor}&countryCode=GB`;
    axiosConfig
      .get(url)
      .then((res) => {
        if (formikValues.toSearchBy.value === 'customernumber' && ((res.data || {}).addressBooks || []).length === 1) {
          rowClicked(res.data.addressBooks[0]);
        } else {
          setCustomerData(res.data.addressBooks);
          setOpenAddressBookModal(true);
        }
      })
      .catch((err) => {
        setCustomerData([]);
        setOpenAddressBookModal(true);
        console.log(err);
      });
  };

  useEffect(() => {
    setFieldValue('toSearchFor', '');
  }, [props.formikValues.toSearchBy]);

  const rowClicked = (val) => {
    const address = val.deliveryAddress;
    const options = val.deliveryOptions;
    setAddressToForm(address, true, options, val);
    setCustomerNumber(address.customerNumber);
    setOpenAddressBookModal(false);
  };

  const setAddressToForm = (address, isAddressBook = true, options, dimsDetails = null) => {
    const addressObj = {
      // toAddressType: address?.type === 'Commercial' ? 'business' : 'residential',
      toAddressType: isAddressBook
        ? address.recipientType === 1
          ? 'business'
          : 'residential'
        : address?.type === 'Commercial'
          ? 'business'
          : 'residential',
      toTown: isAddressBook ? address?.town : address?.city,
      toAddressLine1: address?.line1,
      toAddressLine2: address?.line2,
      toAddressLine3: address?.line3,
      toPostCode: isAddressBook ? address?.postCode?.toUpperCase() : address?.postalCode?.toUpperCase(),
      toBusinessName: isAddressBook ? address.businessName : address?.company,
      toCustomerNo: address?.customerNumber,
      toEmailAddress: address?.email,
      toContactName: address?.recipientName,
      toTelephoneNumber: address?.telephone,
      toCounty: isAddressBook ? address?.county : address?.provinceName,
      deliveryType: options?.deliveryType === 2 ? 'deliverToServicePoint' : 'deliverToDoorStep',
      doorStepInstructions: setDropdownValues(doorStepInstructionOptions, 'value', options?.doorStepInstructions),
      what3Words: address?.what3Words?.words,
      w3WCoordinates: address?.what3Words?.geo,
    };
    removeUndefinedProperties(addressObj);
    setValues({ ...formikValues, ...addressObj });
    dispatch(
      actions.setLoadedFrom({
        loadedFrom: 'dropdownResponse',
        loadedResponse: { ...dimsDetails },
      }),
    );
    setTimeout(() => {
      dispatch(
        actions.setLoadedFrom({
          loadedFrom: '',
          loadedResponse: {},
        }),
      );
    }, 5000);
    setValuesToForm(dimsDetails);
  };
  const handleClose = () => {
    setOpenAddressBookModal(false);
  };


  useEffect(() => {
    if (formikValues.fromPostCode && formikValues.fromWeight) {

      const destinationAddressType = formikValues.deliveryType === 'deliverToDoorStep' ? 'doorstep' : 'servicePoint';
      // Removed all accounts due API character limit.
      //  const pickupAccount = (displayAccounts) ? arrayToString(displayAccounts, 'pickupAccount', 'accountId') : '';
      
       // Sending only selected account.
      const pickupAccount = `&pickupAccount=${selectedAccount?.accountId}`;
      
      abortOnGoingCallsFor(ServiceEndPoints.getProducts);
      axiosConfig
        .get(
          `${ServiceEndPoints.getProducts}DestinationPostalCode=${formikValues.returnType === 'returnToSender'
            ? selectedAccount?.sender?.postalCode
            : formikValues?.toPostCode
          }&DestinationCountryCode=GB&DestinationRecipientType=${formikValues.returnType === 'returnToSender' ? 'business' : formikValues.toAddressType
          }&weight=${formikValues?.fromWeight}&OriginPostalCode=${formikValues.fromPostCode
          }&OriginCountryCode=GB&parcels=${formikValues?.fromParcel
          }&destinationAddressType=${destinationAddressType}${pickupAccount}`,
        )
        .then((res) => {
          if (res.data.length > 0) {
            dispatch(actions.setAllAvailableProducts(res.data))
            let min = Math.min.apply(
              null,
              res.data.map((item) => item.sortOrder),
            );
            let minSortOrderData = res.data.find((services) => services.sortOrder === min);
            setServices(minSortOrderData?.deliveryCapabilities?.services || []);
            // getServicesForCollectionReq(minSortOrderData?.deliveryCapabilities?.services || []);
          }
          else {
            dispatch(actions.setAllAvailableProducts([]))
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(actions.setAllAvailableProducts([]))
          setServices([]);
        });
    }
  }, [
    selectedAccount,
    formikValues?.fromWeight,
    formikValues?.fromParcel,
    formikValues.returnType,
    formikValues?.toPostCode,
    formikValues.toAddressType,
    formikValues.deliveryType,
    formikValues.fromPostCode,
  ]);

  useEffect(() => {
    if (allProducts.length > 0) {
      let products = allProducts;
      switch (formikValues.doorStepInstructions.value) {
        case "deliverToNeighbour":
          products = allProducts.filter(p => p.deliveryCapabilities.neighbourDelivery);
          break;
        case "leaveSafe":
          products = allProducts.filter(p => p.deliveryCapabilities.secureLocationDelivery);
          break;
        case "deliverToDoorStepOnly":
          products = allProducts.filter(p => (!p.deliveryCapabilities.neighbourDelivery &&
            !p.deliveryCapabilities.secureLocationDelivery));
          break;
        default:
          products = allProducts;
      }
      let min = Math.min.apply(
        null,
        products.map((item) => item.sortOrder),
      );
      let minSortOrderData = products.find((services) => services.sortOrder === min);
      setServices(minSortOrderData?.deliveryCapabilities?.services || []);
    }
  }, [allProducts, formikValues.doorStepInstructions])

  const setValuesToPostalSearch = (val) => {
    const address = val.data[0];
    setToAddressSearchObj(val);
    setAddressToForm(address, false);
    // setCustomerNumber(val.data[0].customerNumber);
  };

  // useEffect(() => {
  //   setFieldValue('toAddressType', 'residential');
  // }, []);

  useEffect(() => {
    if (formikValues.toAddressType === 'residential') {
      setFieldValue('toBusinessName', '');
    }
  }, [formikValues.toAddressType]);

  const loadedFrom = useSelector((state) => state.loadedFrom.loadedFrom);

  useEffect(() => {
    setDefaultValues('toServiceRequired', setDropdownValues(services, 'name', 'Next day'));
  }, [services]);

  useEffect(() => {
    if (shouldSetValues) {
      const reqObj = {
        // toServiceRequired: setDropdownValues(services, 'name', shouldSetValues?.service),
        specialInstructions: shouldSetValues?.deliveryOptions?.specialInstructions ?? '',
        leaveSafeLocation: shouldSetValues?.deliveryOptions?.leaveSafeLocation ?? '',
        additionalInstruction: shouldSetValues?.deliveryOptions?.additionalInstruction ?? '',
      };
      setValues({ ...formikValues, ...reqObj });
    }
  }, [setValues, shouldSetValues]);

  useEffect(() => {
    if (shouldSetValues && services.length > 0) {
      const reqObj = {
        toServiceRequired: setDropdownValues(services, 'name', shouldSetValues?.service),
      };
      setValues({ ...formikValues, ...reqObj });
      setValuesToForm(false);
    }
  }, [shouldSetValues, services]);

  useEffect(() => {
    const loadedFromScreen = loadedFrom.loadedFrom;
    const loadedResponse = loadedFrom.loadedResponse;
    if (
      services?.length > 0 &&
      (loadedFromScreen === 'dropdownResponse' || loadedFromScreen === 'fromPreviewSummary') &&
      (loadedResponse || {}).service
    ) {
      const selectedService = setDropdownValues(services, 'name', loadedResponse.service?.description);
      setFieldValue('toServiceRequired', selectedService);
    }
  }, [services, loadedFrom]);

  useEffect(() => {
    if (updateValidationSchema) {
      if (!formikValues?.accountNumber?.configuration?.consigneeEmailOptional) {
        !(toEmailAddress.validation.validations.find((x) => x.type === 'required')) && toEmailAddress.validation.validations.push({
          type: 'required',
          params: ['Email address is required'],
        });
        toEmailAddress.props.isRequired = true;
      }
      else {
        toEmailAddress.validation.validations = toEmailAddress.validation.validations.filter((x) => x.type !== 'required');
        toEmailAddress.props.isRequired = false;
      }
      updateValidationSchema();
    }
  }, [formikValues.accountNumber]);

  const isBusiness = () => {
    return (props.formikValues || {}).toAddressType === 'business';
  };

  const setDefaultValues = (key, value) => {
    setFieldValue(key, value);
  };

  const customerNumberBlurred = (eve) => {
    setCustomerNumber(eve.target.value);
  };

  // useEffect(() => {
  //   const { toPostCode } = formikValues;
  //   if (toPostCode) {
  //     setFieldValue('deliveryServicePoint', toPostCode);
  //   }
  // }, [formikValues.toPostCode]);

  const setAddressValues = (addressObj) => {
    const formObj = {
      toTown: addressObj.city,
      toAddressLine1: addressObj.line1,
      toAddressLine2: addressObj.line2,
      toAddressLine3: addressObj.line3,
      toPostCode: addressObj.postalCode,
      toCounty: addressObj.provinceName,
      toBusinessName: addressObj.company,
    };
    // removeUndefinedProperties(formObj);
    setValues({ ...formikValues, ...formObj });
  };

  const handleAddressChangeByPostCode = (addressTypes) => {
    if (addressTypes) {
      if (addressTypes === 'Commercial') {
        setFieldValue('toAddressType', addressType[0].value);
      } else {
        setFieldValue('toAddressType', addressType[1].value);
      }
    }
  };
  return (
    <Grid className="wd-100">
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormRenderer {...toAddressType.props} data={addressType} fullWidth />
        </Grid>
        <Grid item>
          <FormRenderer
            {...toSearchFor.props}
            selectProps={toSearchFor.selectProps.props}
            textProps={toSearchFor.textProps.props}
            data={SearchByOptions}
            onSearchClick={getCustomerAddressBook}
            isIconDisabled={formikValues.toSearchFor?.trim() === ''}
            fullWidth
          />
          <CustomDialog
            open={openAddressBookModal}
            onClose={handleClose}
            title={customerData.length < 1 ? 'Customer not found' : 'Customer’s address search'}
          >
            <CustomAddressTable rows={customerData} rowClicked={rowClicked} />
          </CustomDialog>
        </Grid>
        <Grid item>
          <FormRenderer {...toCustomerNo.props} onBlur={(event) => customerNumberBlurred(event)} fullWidth />
        </Grid>
        <Grid item className="delivery-Address">
          <AddressSearch
            setAddressValues={setAddressValues}
            addressType={formikValues.toAddressType}
            destinationCountrycode2Digit="GB"
            onSelectAddress={handleAddressChangeByPostCode}
            isDomestic={true}
          />
        </Grid>
        <Grid item>
          <FormRenderer
            {...toPostCode.props}
            fullWidth
          />
        </Grid>
        <Grid item>
          <FormRenderer {...toContactName.props} fullWidth />
        </Grid>
        {isBusiness() ? (
          <Grid item>
            <FormRenderer {...toBusinessName.props} fullWidth />
          </Grid>
        ) : null}
        <Grid item>
          <FormRenderer {...toAddressLine1.props} fullWidth />
        </Grid>
        <Grid item>
          <FormRenderer {...toAddressLine2.props} fullWidth />
        </Grid>
        <Grid item>
          <FormRenderer {...toAddressLine3.props} fullWidth />
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="space-evenly" spacing={2}>
            <Grid item xs={6}>
              <FormRenderer {...toTown.props} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <FormRenderer {...toCounty.props} fullWidth />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <FormRenderer {...toTelephoneNumber.props} fullWidth />
        </Grid>
        <Grid item>
          <FormRenderer {...toEmailAddress.props} fullWidth />
        </Grid>
        <Grid item>
          <AutoSuggestW3W formikValues={formikValues} setFieldValue={setFieldValue} formField={props.formField} isDisabled={false}/>
        </Grid>
      </Grid>
      <br></br>
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <b>Delivery details</b>
        </Grid>
      </Grid>
      <Grid container className="deliveryTypeContainer" direction="row" spacing={1}>
        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormRenderer {...deliveryType.props} data={deliveryTypeOption} fullWidth />
        </Grid> */}
      </Grid>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <DeliveryOptions
            formField={props.formField}
            formikValues={formikValues}
            setFieldValue={setFieldValue}
            errors={errors}
            isDomestic={true}
            isCarriageForward={true}
            servicePointAddress={servicePointAddress}
          />
          {/* <FormRenderer {...doorStepInstructions.props} data={doorStepInstructionOptions} fullWidth /> */}
        </Grid>
        {/* <Grid item>
          <FormRenderer {...specialInstructions.props} fullWidth />
        </Grid> */}
      </Grid>
      <br></br>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <b>Service details</b>
        </Grid>
        <Grid item>
          <FormRenderer {...toServiceRequired.props} data={services} fullWidth />
        </Grid>
      </Grid>
      <br></br>
      <Divider />
      <AddToAddressAndFav
        formField={props.formField}
        formikValues={formikValues}
        customerNumber={customerNumber}
        setFieldValue={setFieldValue}
        addToAddressBookControl="toAddToaddressBook"
        addToFavControlName="toAddToFavourites"
        updateAddressBookControl="updateToAddToaddressBook"
        customerNumberControl="toCustomerNo"
        addressSearchObj={setToAddressSearchObj}
      />
    </Grid>
  );
};

DeliverToThirdParty.propTypes = {
  formField: PropTypes.object,
  errors: PropTypes.object,
  formikValues: PropTypes.object,
  setFieldValue: PropTypes.func,
};

export default DeliverToThirdParty;

const addressType = [
  {
    value: 'business',
    label: 'Business',
  },
  {
    value: 'residential',
    label: 'Residential',
  },
];
