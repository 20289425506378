import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { getYupSchemaFromMetaData } from '../../../../../utils/yupSchema/yupSchemaGenerator';
import CustomAccordion from '../../../../../framework/accordion/customAccordion';
import ParcelDetails from '../../../../../container/shipment/addShipment/parcelDetails/parcelDetails';
import DeliveryOptions from '../../../../../container/shipment/addShipment/deliveryOptions/deliveryOptions';
import ReturnOptions from '../../../../../container/shipment/addShipment/returnOptions/returnOptions';
import domesticDefaultsForm from '../../../../../utils/formConfig/configuration/orderFileDefaultsForm/domesticDefaultsForm';
import FormRenderer from '../../../../../framework/Inputs/formRenderer/formRenderer';
import InputButton from '../../../../../framework/Inputs/inputButton/inputButton';
import DefaultDomesticAccount from '../domesticDefaultAccounts';
import { PATCH } from '../../../../../api/axios';
import {
  formatParcelDetails,
  formatDeliveryOptions,
  setValuesToParcelDetails,
  setValuesToDeliveryOptions_domaesticDefaults,
  removeUndefinedProperties,
  getCustomerAccountID,
  getDomesticAccounts,
  getDriverCollectionAccounts,
} from '../../../../../utils/helperFunctions';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import { ServiceEndPoints } from '../../../../../utils/constants/ApiConstant';
import CustomModal from '../../../../../framework/modal/modal';
import ConfirmationDialog from '../../../../addressBook/confirmation/confirmation';
import OrderedProduct from '../orderedProduct/orderedProduct';
import OrderFileDefaultsAdditionalInfo from '../orderFileDefaultsAdditionalInfo/orderFileDefaultsAdditionalInfo';
const { formId, formField } = domesticDefaultsForm;


const DomesticDefaults = (props) => {
  const { domesticDefaultValues, getOrderDefaults, isFromMarketplace } = props;
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const dispatch = useDispatch();

  const userAccounts = useSelector((state) => state?.accounts?.nonReturnuserAccounts ?? []);
  const inboxReturnLabelValue = useSelector(
    (state) => state?.common?.commonConfiguration?.defaultPrintInboxReturnLabel,
  );
  let allDomesticAccounts = getDomesticAccounts(userAccounts);
  let domesticAccounts = getDriverCollectionAccounts(allDomesticAccounts);
  // const orderFileDetailsValidationSchema = getYupSchemaFromMetaData(formField, [], []);
  const [orderFileDetailsValidationSchema, setOrderFileDetailsValidationSchema] = useState(
    getYupSchemaFromMetaData(formField, [], []),
  );

  const formikRef = useRef(null);
  const initialValues = {};
  let vWDivisor = 4000;

  for (let property in formField) {
    if (Object.prototype.hasOwnProperty.call(formField, property)) {
      const field = formField[property];
      if (field.props.type === 'checkbox' || field.props.type === 'radio') {
        initialValues[field.props.name] = field.value;
      } else if (field.props.type === 'array') {
        initialValues[field.props.name] = field.values;
      } else {
        initialValues[field.props.name] = field.value || '';
      }
    }
  }

  useEffect(() => {
    if (domesticDefaultValues) {
      domesticDefaultValues['destinationCountry'] = 'GBR';

      const selAccount = domesticAccounts.find((acc) => acc.accountId === domesticDefaultValues.account);
      vWDivisor = selAccount?.configuration?.volumetricWeightDivisor || 4000;
      const exchangeValue = domesticDefaultValues?.deliveryOptions?.exchangeOnDelivery?.toString();
      const defaultValues = {
        ...setValuesToParcelDetails(domesticDefaultValues, vWDivisor),
        ...setValuesToDeliveryOptions_domaesticDefaults(domesticDefaultValues),
        extendedCoverDomestic: domesticDefaultValues?.extendedLiability?.extendedLiabilityUnits ?? '',
        inboxReturnLabel: selAccount?.returnConfiguration?.inboxReturnAllowed ? domesticDefaultValues?.includeReturnLabel?.toString() ?? null : false,
        exchange: exchangeValue ? domesticDefaultValues?.deliveryOptions?.exchangeOnDelivery?.toString() === 'true' ? 'yes' : 'no' : null,
        returnReference: domesticDefaultValues.returnreference,
      };
      formikRef?.current?.setValues({ ...formikRef?.current?.values, ...defaultValues });
      const deliveryOptions = domesticDefaultValues.deliveryOptions;
      const defaultSpecialInstructions = deliveryOptions?.additionalInstruction ? `${(deliveryOptions?.specialInstructions ?? '')} ${deliveryOptions.additionalInstruction}` : (deliveryOptions?.specialInstructions ?? '');
      setTimeout(() => {
        formikRef?.current?.setFieldValue('specialInstructions', defaultSpecialInstructions);
        formikRef?.current?.setFieldValue('additionalInstruction', '');
        formikRef?.current?.setFieldValue('leaveSafeLocation', deliveryOptions?.leaveSafeLocation ?? '');
        domesticAccounts.map((account, index) => {
          if (domesticDefaultValues.account === account.accountId) {
            formikRef?.current?.setFieldValue('defaultDomesticAccounts', domesticAccounts[index]);
          }
        });
      }, 10);
      dispatch(actions.setLoadedFrom({ loadedFrom: 'dropdownResponse', loadedResponse: domesticDefaultValues }));
      setTimeout(() => {
        dispatch(actions.setLoadedFrom({ loadedFrom: '' }));
      }, 3000);
    }
  }, [domesticDefaultValues]);



  const reqFields = [
    'destinationCountry',
    'pallets',
    'addParcelDimensions',
    'parcelDetails',
    'totalVolumetricWeight',
    'weight',
    'deliveryType',
    'doorStepInstructions',
    'leaveSafeLocation',
    'exchange',
    'specialInstructions',
    'additionalInstruction',
    'product',
    'service',
    'extendedCoverDomestic',
    'noOfItems',
    '',
  ];
  const isFormInValid = (errors, values) => {
    let clonedReqFields = [...reqFields];

    for (let err in errors) {
      if (clonedReqFields.some((fieldName) => fieldName === err)) {
        return true;
      }
    }

    return false;
  };
  const handleSubmit = (formikValues, errors) => {
    if (!isFormInValid(errors, formikValues)) {
      const reqObj = {
        account: formikValues.defaultDomesticAccounts.accountId,
        deliveryOptions: formatDeliveryOptions(formikValues, true),
        parcelDetails: formatParcelDetails(formikValues, true),
        extendedLiability: {
           extendedLiabilityUnits: (formikValues?.defaultDomesticAccounts?.configuration?.restrictExtendedLiability ? null : (formikValues.extendedCoverDomestic || null)),
        },
        orderedProduct: formikValues.service?.orderedProduct,
        includeReturnLabel: formikValues.inboxReturnLabel === undefined ? inboxReturnLabelValue : formikValues.inboxReturnLabel === 'true',
        returnreference: formikValues.returnReference,
      };
      removeUndefinedProperties(reqObj);
      if(isFromMarketplace){
        PATCH(`${ServiceEndPoints.getMarketplaceDefaults}/domesticdefaults?organization=${getCustomerAccountID()}`, reqObj)  
          .then((res) => {
            dispatch(actions.setAppSnackBarData({ msg: 'Domestic marketplace defaults saved successfully' }));
            getOrderDefaults();
          })
          .catch((err) => {
            console.log(err);
          });
      }else{
        PATCH(`${ServiceEndPoints.getOrderDefaults}/domestic?organization=${getCustomerAccountID()}`, reqObj)  
          .then((res) => {
            dispatch(actions.setAppSnackBarData({ msg: 'Domestic order file defaults saved successfully' }));
            getOrderDefaults();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      dispatch(
        actions.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'The form has errors, please correct and try again',
            },
          ],
        }),
      );
    }
  };

  const handleDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  const resetForm = () => {
    formikRef?.current?.resetForm();
    handleDialogClose();
    formikRef.current.setFieldValue('deliveryType', deliveryTypeOptions[0].value);
  };

  const updateValidationSchema = () => {
    setOrderFileDetailsValidationSchema(getYupSchemaFromMetaData(formField, [], []));
  };

  return (
    <Grid container spacing={2} direction="column">
      <Formik
        initialValues={initialValues}
        validationSchema={orderFileDetailsValidationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
      >
        {({ values, setFieldValue, setFieldTouched, errors }) => {
          let showReturnLabelBasedNoitem = true;
          if (values.noOfItems > 1 || values.weight > 20) {
            showReturnLabelBasedNoitem = false;
          }
          const selectedAccount = values.defaultDomesticAccounts;
          const showReturnLabel = selectedAccount?.returnConfiguration?.inboxReturnAllowed ?? false;
          return (
            <Form id={formId} noValidate>
              <Grid>
                <CustomAccordion heading="Account setup">
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <DefaultDomesticAccount
                      formField={formField}
                      formikValues={values}
                      setFieldValue={setFieldValue}
                      tabIndex={props.tabIndex}
                      domesticDefaultValues={props.domesticDefaultValues}
                      domesticAccounts={domesticAccounts}
                    />
                  </Grid>
                </CustomAccordion>

                <CustomAccordion heading="Parcel details">
                  <ParcelDetails
                    formField={formField}
                    formikValues={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    isDomestic={true}
                    setFieldTouched={setFieldTouched}
                    isOrderDefaults={true}
                    updateValidationSchema={updateValidationSchema}
                  />
                </CustomAccordion>
                <CustomAccordion heading="Delivery options">
                  <DeliveryOptions
                    formField={formField}
                    formikValues={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    isDomestic={true}
                    isOrderDefaults={true}
                    isSelectDefaultDoorstepinst={true}
                    updateValidationSchema={updateValidationSchema}
                  />
                </CustomAccordion>
                <CustomAccordion heading="Product and service">
                  <OrderedProduct
                    formField={formField}
                    formikValues={values}
                    setFieldValue={setFieldValue}
                    isDomestic={true}
                    errors={errors}
                    selectedAccount={selectedAccount}
                  />
                </CustomAccordion>
                {selectedAccount?.configuration?.restrictExtendedLiability !== true  && <CustomAccordion heading="Additional information about your shipment">
                  <OrderFileDefaultsAdditionalInfo 
                   formField={formField}
                   formikValues={values}
                   isDomestic={true}
                   setFieldValue={setFieldValue}
                  />
                </CustomAccordion> }

                {showReturnLabel && showReturnLabelBasedNoitem ? (
                  <CustomAccordion heading="Return option" className="returnOptions-accordion">
                    <ReturnOptions
                      setFieldValue={setFieldValue}
                      formField={formField}
                      formikValues={values}
                      isOrderFileDefault={true}
                    />
                  </CustomAccordion>
                ) : null}
                <Grid className="domestic-btn-container" container direction="row" justify="flex-end" spacing={3}>
                  <Grid item>
                    <InputButton
                      variant="outlined"
                      className="outlined-btn"
                      label="Reset"
                      onClick={() => setShowConfirmationDialog(true)}
                    />
                  </Grid>
                  <Grid item>
                    <InputButton
                      variant="contained"
                      className="contained-btn"
                      buttonType="submit"
                      onClick={() => handleSubmit(values, errors)}
                      label="Save changes"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      <CustomModal open={showConfirmationDialog} title="Reset Form" onClose={handleDialogClose} maxWidth="sm">
        <ConfirmationDialog
          confirmationMessage="Are you sure you want to reset?"
          onConfirm={resetForm}
          OnCancel={handleDialogClose}
        />
      </CustomModal>
    </Grid>
  );
};

DomesticDefaults.propTypes = {};

const mapStateToProps = (state) => {
  return {
    servicePointSelected: state.addShipmentData.servicePointDelivery,
  };
};

export default connect(mapStateToProps, null)(DomesticDefaults);

const deliveryTypeOptions = [
  {
    value: 'deliverToDoorStep',
    label: 'Deliver to doorstep',
  },
  {
    value: 'deliverToServicePoint',
    label: 'Deliver to ServicePoint',
  },
];
