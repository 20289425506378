import {
  AppBar,
  makeStyles,
  Toolbar,
  Box,
  Menu,
  MenuItem,
  Button,
  Badge,
  ListItemIcon,
  Typography,
  Grid,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DHLLogo from './../../assets/images/dhlLogo.svg';
import iConsignLogo from './../../assets/images/iConsignLogo.svg';
import { PropTypes } from 'prop-types';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import './header.scss';
import { useHistory } from 'react-router';
import TransitionAlerts from '../transitionAlerts/transitionAlerts';
import { GET } from '../../api/axios';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions/index';
import { applicationID, reactRedirectUri, signInPolicy, tenant,instance } from '../../utils/authProvider/authProvider';
import { ServiceEndPoints } from '../../utils/constants/ApiConstant';
import moment from 'moment';
import PrivacyPolicy from './../../assets/images/privacyPolicy.svg';
import BusinessServices from './../../assets/images/businessServices.svg';
import Terms from './../../assets/images/termsAndConditions.svg';
import { formatDate } from '../../utils/helperFunctions';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#fff',
    color: '#000',
    paddingRight: '0px !important'
    //   background:
    //     'transparent linear-gradient(90deg, #FFCC00 0%, #FFCC00 30%, #FFE57F 79%, #FFF0B2 100%) 0% 0% no-repeat padding-box',
  },
}));
const Header = (props) => {
  const { userDetails, isApplication } = props;
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [notificationRead, setnotificationRead] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertInfo, setAlertInfo] = useState([]);
  const [applicationUpdate, setApplicationUpdate] = useState([]);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const deleteAllCookies = () => {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    let logoutUri = reactRedirectUri;
    if (userDetails?.accounts?.length <= 200) {
      const idToken = sessionStorage.getItem(`msal.${applicationID}.idtoken`);
      //logoutUri = `https://${tenant?.split(".")?.[0]}.b2clogin.com/${tenant}/${signInPolicy}/oauth2/v2.0/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${reactRedirectUri}`;
      logoutUri = `https://${instance}/${tenant}/${signInPolicy}/oauth2/v2.0/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${reactRedirectUri}`;
    }
    sessionStorage.clear();
    deleteAllCookies();
    setAnchorEl(null);
    window.open(logoutUri, '_self');
  };

  const handleClickOpen = (event) => {
    setnotificationRead(true)
    setAnchorE2(event.currentTarget);
  };

  const handleClickClose = () => {
    setAnchorE2(null);
  };

  const handleApplication = () => {
    history.push('/applicationUpdates');
    setAnchorE2(null);
  };

  const goToHome = () => {
    history.push('/');
  };

  useEffect(() => {
    //Alert
    GET(ServiceEndPoints.alerts)
      .then((res) => {
        setAlertInfo(res.data.descriptions);
        if (res.data.descriptions?.length > 0) {
          setOpen(true);
          props.alertClosed(true);
        }
        // setAlertInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    //
    GET(ServiceEndPoints.applicationupdates)
      .then((res) => {
        setApplicationUpdate(res.data.applicationUpdates);
        dispatch(actions.setApplicationUpdates(res.data.applicationUpdates));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <TransitionAlerts open={open} setOpen={setOpen} alertInfo={alertInfo} alertClosed={props.alertClosed} />
      <Toolbar className="headerComponent" display="flex" p={1}>
        <Box p={1} flexGrow={1}>
          {props.isLoginPage && !props.isfaqPage ? (
            <img src={DHLLogo} alt="Header Logo" />
          ) : (
            <img
              src={iConsignLogo}
              className="shipLogo"
              alt="Header Logo"
              style={{ width: '49px', height: '33px', marginLeft: '0.5%' }}
              onClick={() => {
                goToHome();
              }}
            />
          )}
        </Box>
        {props.isLoginPage && !props.isfaqPage ? null : (
          <>
            <Box p={1} className="headerContent">
              <Button
                size="small"
                className={open ? 'hiddenAlert' : ''}
                startIcon={<ErrorOutlineOutlinedIcon />}
                disabled={open}
                onClick={() => {
                  setOpen(true);
                  props.alertClosed(true);
                }}
              >
                Alerts({alertInfo.length})
              </Button>
            </Box>
            <Box p={1} className="headerContent">
              <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                {notificationRead ? (
                  <SettingsApplicationsOutlinedIcon />
                ) : (
                  <Badge badgeContent={applicationUpdate.length} color="secondary">
                    <SettingsApplicationsOutlinedIcon />
                  </Badge>
                )}
              </Button>
              <Menu
                id="simple-menu"
                className="updateMenu"
                anchorEl={anchorE2}
                keepMounted
                open={Boolean(anchorE2)}
                onClose={handleClickClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                <span className="app-title">Application updates</span>

                {(applicationUpdate || []).slice(0, 5).map((application, index) => {
                  const applicationDate = formatDate(application.createdOn, '/', 'dd/mm/yy')

                  return (
                    <MenuItem onClick={handleApplication} key={index} justify="space-around">
                      <Grid container direction="row" justify="space-between">
                        <Grid item xs={9} style={{ wordWrap: 'break-All' }}>
                          <span className="applicationText">{application.updateDescription?.length > 35 ? `${application.updateDescription?.slice(0, 40)}...` : application.updateDescription}</span>
                        </Grid>
                        <Grid item xs={3}>
                          <span className="applicationText appl-date">{applicationDate}</span>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  );
                })}
                <MenuItem className="see-all" onClick={handleApplication}>
                  <Typography variant="inherit">See all</Typography>
                  <ListItemIcon>
                    <ArrowForwardIcon />
                  </ListItemIcon>
                </MenuItem>
              </Menu>
            </Box>
            <Box p={1} className="headerContent">
              <SearchIcon />
            </Box>
            <Box p={1}>
              <Button aria-controls="simple-menu" className="btn-menu" aria-haspopup="true" onClick={handleClick}>
                <Typography variant="inherit">
                  {' '}
                  Hello,<b>&nbsp;{userDetails?.name ?? 'Guest'}</b>
                </Typography>
                <ListItemIcon>{Boolean(anchorEl) ? null : <ExpandMoreIcon fontSize="small" />}</ListItemIcon>
              </Button>
              <Menu
                id="simple-menu"
                className={`menuListHeader user-ddl-header ${open ? "alertDialogContainer" : ""}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <MenuItem onClick={handleClose}>
                  <Link className="Redirect-Link" to={'/faq'}>
                    <ListItemIcon>
                      <HelpOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">FAQ</Typography>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <a
                    href="https://www.dhl.com/content/dam/dhl/local/gb/dhl-ecommerce/documents/pdf/gb-ecommerce-ship-user-guide.pdf"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <img
                        src={Terms}
                        alt="User guide logo"
                        style={{ height: '20px', width: '20px', marginLeft: '9%' }}
                      />
                    </ListItemIcon>

                    <Typography variant="inherit">Ship user guide</Typography>
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="https://www.dhl.com/content/dam/dhl/local/gb/core/documents/pdf/gb-dhl-ship-marketplace-user-guide.pdf"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <img
                        src={Terms}
                        alt="User guide logo"
                        style={{ height: '20px', width: '20px', marginLeft: '9%' }}
                      />
                    </ListItemIcon>

                    <Typography variant="inherit">Marketplace user guide</Typography>
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="https://www.dhl.com/content/dam/dhl/local/gb/dhl-ecommerce/documents/pdf/gb-ecommerce-ship-printing-set-up-for-macs.pdf"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <img
                        src={Terms}
                        alt="User guide logo"
                        style={{ height: '20px', width: '20px', marginLeft: '9%' }}
                      />
                    </ListItemIcon>

                    <Typography variant="inherit">Mac printer setup</Typography>
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="https://www.dhl.com/gb-en/home/our-divisions/parcel/business-users/help/live-service-updates.html"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <SettingsOutlinedIcon />
                    </ListItemIcon>

                    <Typography variant="inherit">Live service updates</Typography>
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="https://www.dhl.com/gb-en/home/our-divisions/parcel/business-users/help/business-support.html"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <img
                        src={BusinessServices}

                        alt="privacy Logo"
                        style={{ height: '20px', width: '20px', marginLeft: '9%' }}
                      // onClick={() => {
                      //   goToHome();
                      // }}
                      />
                    </ListItemIcon>

                    <Typography variant="inherit">Business support</Typography>
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="https://www.dhl.com/gb-en/home/our-divisions/parcel/business-users/about/terms-and-conditions.html?_ga=2.139483507.1591425079.1614856324-1666150228.1594818619&_gac=1.117911931.1614185463.Cj0KCQiAj9iBBhCJARIsAE9qRtBPM-n5ZvFTjA7t0oOg6bF-a94t8n7o7NMKyvm5zgu4bvA8_jfF7Q4aAp1WEALw_wcB"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <img
                        src={Terms}

                        alt="privacy Logo"
                        style={{ height: '20px', width: '20px', marginLeft: '9%' }}
                      // onClick={() => {
                      //   goToHome();
                      // }}
                      />
                    </ListItemIcon>

                    <Typography variant="inherit">Terms & conditions</Typography>
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="https://www.dhl.com/gb-en/home/our-divisions/parcel/business-users/about/privacy-notice.html#universal-shipping"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <img
                        src={PrivacyPolicy}

                        alt="privacy Logo"
                        style={{ height: '20px', width: '20px', marginLeft: '9%' }}
                      // onClick={() => {
                      //   goToHome();
                      // }}
                      />
                    </ListItemIcon>

                    <Typography variant="inherit">Privacy policy</Typography>
                  </a>
                </MenuItem>
                <MenuItem>
                  <Typography variant="inherit" id="ot-sdk-btn" class="ot-sdk-show-settings oneTrust-cookie-button ">
                    Cookie Preferences
                  </Typography>
                </MenuItem>
                <MenuItem onClick={logout}>
                  <ListItemIcon>
                    <ExitToAppRoundedIcon />
                  </ListItemIcon>

                  <Typography variant="inherit">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  isLoginPage: PropTypes.bool,
  history: PropTypes.any,
};

export default Header;
